import React from "react"
import Header from "../components/layout/header"
import Layout from "../components/layout/layout"
import Footer from "../components/layout/footer"
import Intro from "../components/intro"
import { withPrefix } from "gatsby"
import { aboutData } from "../data"

export default () => {
  return (
    <Layout>
      <Header title="About Me" />
      <Intro primary="About" secondary="Me" align="" tag="Some personal info">
        <section class="about">
          <div class="about-info">
            <p>
              {aboutData.introP}
              <br /> <br />
            </p>
            <p>
              {aboutData.secondP}
              <br />
              <br />
              {aboutData.skillsP}
              <br />
              {aboutData.skillsP2}
            </p>
          </div>
          <div className="about-picture">
            <img src={withPrefix("pic1.jpg")} alt="" />
          </div>
        </section>
      </Intro>
      <Footer />
    </Layout>
  )
}
